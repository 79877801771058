.mainDiv {
  background-color: #f6f4ff;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.posFeaturesDiv {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #3cba68;
}
.everyFeaturesDiv {
  font-family: Outfit;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  color: #000000;
  padding-bottom: 20px;
}
.cardsDiv {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.card {
  padding: 20px;
  display: flex;
  align-items: start;
  background-color: #ffffff;
  border-radius: 10px;
}
.leftDiv {
  /* background-color: #ff6969; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}
.iconDiv {
  /* padding-right: 20px; */
  width: 60px;
  height: 60px;
  font-size: 40px;
  border-radius: 30px;
  background-color: #6990ff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rightDiv {
}
.title {
  font-family: Outfit;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: 0.25px;
}
.description {
  font-size: 12px;
  opacity: 0.5;
  font-weight: 400;
}
.exploreBtn {
  background-color: #3cba68;
  padding: 10px 20px;
  width: fit-content;
  border-radius: 20px;
  color: white;
  font-weight: 800;
  margin-top: 20px;
  cursor: pointer;
}
.link {
  text-decoration: none;
}
@media (max-width: 1200px) {
  .cardsDiv {
    grid-template-columns: 1fr 1fr;
  }
  .exploreBtn {
    font-size: 14px;
  }
}
@media (max-width: 900px) {
  .posFeaturesDiv {
    font-size: 18px;
  }
  .everyFeaturesDiv {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .cardsDiv {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 576px) {
  .posFeaturesDiv {
    font-size: 14px;
  }
  .everyFeaturesDiv {
    font-size: 14px;
  }
  .title {
    font-size: 14px;
  }
  .iconDiv {
    /* padding-right: 20px; */
    width: 40px;
    height: 40px;
    font-size: 30px;
    border-radius: 20px;
  }
  .exploreBtn {
    font-size: 12px;
  }
}
