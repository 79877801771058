html {
  scroll-behavior: smooth;
}
.topNavContainer a {
  text-decoration: none;
}

.topNavContainer {
  height: 100px;
  display: flex;
  align-items: center;
  z-index: 10;
  background-color: #33333300;
}

.burgerIconDiv {
  padding-left: 50px;
  font-size: 40px;
  color: white;
  display: none;
}

.mobNav {
  position: fixed;
  background-color: rgb(255, 255, 255);
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 90%;
  padding: 60px 50px;
  overflow-y: auto;
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;
}

.mobNavShow {
  transform: translateX(0);
}

.left {
  display: flex;
  align-items: center;
}

.logoDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.logo {
  height: 50px;
  margin-right: 10px;
  color: rgb(0, 0, 0);
  background-color: black;
  padding: 5px;
  border-radius: 50%;
}

.name {
  font-size: 32px;
  font-weight: 900;
  color: rgb(0, 0, 0);
}

.right {
  color: black;
  font-size: 30px;
  height: 100%;
  display: flex;
  align-items: start;
  padding-right: 50px;
}

.mobNavLink {
  font-size: 20px;
  padding: 20px 0;
  font-weight: 500;
  color: black;
  font-weight: 800;
  cursor: pointer;
}

.navLinkContainer {
  display: flex;
  font-weight: 500;
  color: white;
  align-items: center;
  margin-left: auto;
  right: 0;
  padding: 0 20px 0 20px;
}

.navLink {
  letter-spacing: 1px;
  display: block;
  margin: 0 15px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 800;
  color: white;
  cursor: pointer;
}

.nav-link:hover {
  /* color: green; */
}
a {
  /* ?? */
}
@media (max-width: 1200px) {
  .topNavContainer {
    position: relative;
  }
  .navLinkContainer {
    display: none;
  }
  .burgerIconDiv {
    display: flex;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
}
