.mainDiv {
  background-color: #ffffff;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Outfit;
}
.posFeaturesDiv {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #3cba68;
}
.everyFeaturesDiv {
  font-family: Outfit;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  color: #000000;
  padding-bottom: 20px;
}
.contentDiv {
  display: flex;
  align-items: center;
  width: 100%;
}
.leftDiv {
  /* background-color: aqua; */
  width: 40%;
}
.img {
  width: 75%;
}
.rightDiv {
  width: 60%;
  padding-left: 20px;
}
.nkn {
  padding: 0;
  margin: 0;
  background-color: var(--input-box-color);
}
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-top: 20px; */
}
.upperDiv {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.04em;
  padding: 20px 20px 20px 20px;
}
.q {
  color: #6990ff;
}
.icon {
  padding: 20px 20px 20px 20px;
  font-size: 24px;
  color: #6990ff;
}
.paragraph {
  font-size: 16px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.04em;
  opacity: 75%;
  z-index: -1;

  color: var(--faq-answer-color);
}

.noParagraph {
  visibility: hidden;
  position: absolute;
}
@media (max-width: 900px) {
  .posFeaturesDiv {
    font-size: 18px;
  }
  .everyFeaturesDiv {
    font-size: 18px;
  }
  .leftDiv {
    display: none;
  }
  .rightDiv {
    width: 100%;
    padding-left: 0;
  }
}
@media (max-width: 576px) {
  .posFeaturesDiv {
    font-size: 14px;
  }
  .everyFeaturesDiv {
    font-size: 14px;
  }
  .title {
    font-size: 12px;
    padding: 15px 0px 15px 0px;
  }
  .icon {
    padding: 15px 0px 15px 0px;
    font-size: 18px;
  }
  .paragraph {
    font-size: 12px;
    color: var(--faq-answer-color);
  }
}
