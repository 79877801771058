.customDot {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  background-color: #52c8c4;
}

.customDot.active {
  width: 20px;
}

.customDot.inactive {
  opacity: 50%;
}
