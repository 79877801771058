.button-outlined-body {
  background-color: transparent;
  border-color: white;
  border-width: 2px;
  border-style: solid;
  width: 100%;
  height: 40px;
  border-radius: 40px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  color: white;
  margin: 0 10px;
  cursor: pointer;
  background: linear-gradient(to left, transparent 50%, #3cba68 50%) right;
  background-size: 200%;
  transition: 0.5s ease-out;
}

.button-outlined-body:hover {
  color: white;
  border-color: #3cba68;
  background-position: left;
}

@media (max-width: 576px) {
  .button-outlined-body {
    width: 100%;
    height: 25px;
    font-size: small;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
