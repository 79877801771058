.mainDiv {
  background-color: rgb(255, 255, 255);
  padding: 30px 60px;
  display: flex;
  justify-content: center;
}
.innerDiv {
  display: grid;
  grid-template-columns: 4fr 6fr;
  gap: 20px;
  max-width: 1440px;
}
.leftDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img {
  height: 400px;
}
.rightDiv {
  display: flex;
  align-items: center;
}
.infoDiv {
}
.titleDiv {
  font-size: 35px;
  font-weight: 600;
}
.imgMob {
  display: none;
}
.descriptionDiv {
  font-size: 18px;
  opacity: 60%;
  font-weight: 300;
}
.demoBtn {
  background-color: #3cba68;
  padding: 10px 20px;
  width: fit-content;
  border-radius: 10px;
  color: white;
  font-weight: 800;
}
.link {
  text-decoration: none;
}
@media (max-width: 1200px) {
  .mainDiv {
    padding: 30px 50px;
  }
  .img {
    height: 300px;
  }
  .titleDiv {
    font-size: 28px;
  }
  .demoBtn {
    font-size: 14px;
  }
}
@media (max-width: 1000px) {
  .img {
    height: 200px;
  }
}
@media (max-width: 900px) {
  .innerDiv {
    display: flex;
    /* justify-content: center; */
  }
  .leftDiv {
    display: none;
  }
  .infoDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .titleDiv {
    margin-bottom: 20px;
    text-align: center;
    font-size: 30px;
  }
  .imgMob {
    display: flex;
    height: 150px;
    margin-bottom: 10px;
  }
  .demoBtn {
    width: 150px;
    display: flex;
    justify-content: center;
  }
  .descriptionDiv {
    margin-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .descriptionDiv {
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .titleDiv {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .imgMob {
    height: 100px;
    margin-bottom: 0px;
  }
  .descriptionDiv {
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .imgMob {
    height: 100px;
  }
}
