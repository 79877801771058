.mainDiv {
  background: linear-gradient(
    90deg,
    rgba(255, 239, 255, 1) 26%,
    rgba(241, 252, 255, 1) 75%
  );
  padding: 30px 60px;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
}
.posFeaturesDiv {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #3cba68;
}
.everyFeaturesDiv {
  font-family: Outfit;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  color: #000000;
  padding-bottom: 20px;
}
.cardDiv {
  padding: 0 20px 20px 20px;
  margin-bottom: 20px;
}
.card {
  cursor: pointer;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.comment {
  font-size: 20px;
  color: #7c7c7c;
  margin-bottom: 20px;
  word-wrap: break-word;
}
.bottomDiv {
  display: flex;
  width: 100%;
}
.left {
  /* background-color: #3cba68; */
}
.dpDiv {
  position: relative;
}
.dp {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  /* position: relative; */
}
.iconDiv {
  position: absolute;
  background-color: white;
  border: #6990ff 1px solid;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  right: 20px;
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6990ff;
}
.right {
}
.name {
  font-size: 20px;
  font-weight: 600;
}
.post {
  color: #7c7c7c;
  margin-bottom: 10px;
}
.starsBack {
  color: #ffbe17;
  opacity: 10%;
  position: absolute;
}

.starInactive {
}
.starsFront {
  position: relative;
  color: #ffbe17;
}
.starActive {
}

@media (max-width: 1200px) {
  .dp {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
  }
  .name {
    font-size: 16px;
    font-weight: 600;
  }
  .post {
    font-size: 14px;
  }
  .comment {
    font-size: 18px;
  }
  .iconDiv {
    height: 20px;
    width: 20px;
    right: 15px;
  }
  .icon {
    font-size: 14px;
  }
}
@media (max-width: 1000px) {
  .comment {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .posFeaturesDiv {
    font-size: 18px;
  }
  .everyFeaturesDiv {
    font-size: 18px;
  }
}
@media (max-width: 576px) {
}
@media (max-width: 500px) {
  .bottomDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .comment {
    text-align: center;
  }
  .dp {
    margin-right: 0;
  }
}
