.top-container {
  height: 650px;
  width: 100%;
  background-image: url("../../assets/bg-top-container.png");
  background-position: 0px -100px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -100px;
  z-index: -1;
}
a {
  text-decoration: none;
}
.pos-img {
  display: none;
}
.top-container-body {
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  padding-top: 60px;
  z-index: -2;
  /* background-color: #02295b; */
}

.top-container-body h1,
h2,
h3 {
  padding: 0;
  margin: 0;
  color: white;
}

.top-container-body h1 {
  font-size: 40px;
}

.top-container-body h2 {
  font-size: 30px;
  font-weight: normal;
}

.top-container-body p {
  font-size: 20px;
  font-weight: 100;
  color: white;
  padding: 0;
  margin: 0;
  width: 600px;
}

.top-container-logo {
  background-image: url("../../assets/logo.png");
  display: flex;
  flex-direction: column;
  height: 84px;
  width: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.maximize {
  font-size: 40px;
  font-weight: 500;
  color: white;
}

.erp {
  font-size: 50px;
  font-weight: 800;
  color: white;
  margin-bottom: 10px;
}
.user {
  font-size: 20px;
  font-weight: 500;
  color: white;
  width: 50%;
}
.free-trial {
  background-color: #3cba68;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  font-size: 20px;
  font-weight: 800;
  border-radius: 30px;
  color: white;
  cursor: pointer;
}

.talk-to-sales {
  background-color: #00224e;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  font-size: 20px;
  font-weight: 800;
  border-radius: 30px;
  color: white;
  border: 1px solid white;
  cursor: pointer;
}
.top-container-body-content {
  padding-left: 10px;
  margin-top: 50px;
}

.button-container {
  display: flex;
  margin-top: 50px;
  width: 400px;
}

.pos-set {
  background-image: url("../../assets/pos-set.png");
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 900px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: end;
  margin-left: auto;
  margin-top: -250px;
}
@media (max-width: 1850px) {
  .pos-set {
    height: 320px;
    width: 600px;
    margin-top: -250px;
  }
}
@media (max-width: 1500px) {
  .pos-set {
    height: 320px;
    width: 600px;
    margin-top: -250px;
  }

  .top-container {
    background-position: 0px -50px;
  }
}
@media (max-width: 1200px) {
  .top-container {
    height: auto;
    background-image: url("../../assets/bg-top-container-mob.png");
  }
  .img-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .pos-img {
    display: flex;
    width: 500px;
  }
  .pos-set {
    /* height: 320px;
    width: 600px;
    margin-top: -200px; */
    display: none;
  }
  .user {
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }
  .top-container-body p {
    width: 500px;
    display: flex;
    justify-content: center;
  }

  .top-container-body-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .top-container {
    background-position: 0px 0px;
  }

  .button-container {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
  }

  .talk-to-sales {
    background-color: #00224e00;
  }
  .maximize {
    text-align: center;
  }
  .erp {
    text-align: center;
  }
}
@media (max-width: 1000px) {
  .pos-set {
    height: 380px;
    width: 700px;
    margin-top: -120px;
  }
}

@media (max-width: 1012px) {
  .pos-set {
    height: 380px;
    width: 700px;
    margin-top: -90px;
  }
}

@media (max-width: 945px) {
  .pos-set {
    height: 300px;
    width: 600px;
    margin: auto;
  }
}

@media (max-width: 900px) {
  .maximize {
    font-size: 30px;
  }
  .erp {
    font-size: 40px;
    margin-bottom: 10px;
  }

  .pos-img {
    width: 400px;
  }
}

@media (max-width: 1200px) {
  .top-container-body {
    padding-top: 100px;
  }
}

@media (max-width: 900px) {
  .pos-set {
    height: 40vh;
    width: 500px;
    margin: auto;
    margin-top: 20px;
  }

  .top-container-body h1 {
    font-size: 40px;
  }

  .top-container-body h2 {
    font-size: 25px;
  }

  .top-container-body p {
    font-size: 18px;
    width: 500px;
  }

  .top-container-body-content {
    margin-top: 20px;
  }
}
/* @media (max-width: 576px) {
  .pos-set {
    height: 150px;
    width: 300px;
    margin: auto;
    margin-top: 30px;
  }

  .top-container-logo {
    height: 40px;
    width: 150px;
  }

  .top-container-body {
    padding: 10px;
    padding-top: 160px;
  }

  .top-container-body h1 {
    font-size: 20px;
  }

  .top-container-body h2 {
    font-size: 18px;
  }

  .top-container-body p {
    font-size: 12px;
    width: 250px;
  }

  .top-container-body-content {
    margin-top: 20px;
  }

  .button-container {
    display: flex;
    margin-top: 20px;
    width: 300px;
  }

  .top-container {
    height: 500px;
    width: 100%;
  }
} */
@media (max-width: 768px) {
  .maximize {
    font-size: 28px;
  }
  .erp {
    font-size: 35px;
    margin-bottom: 10px;
  }

  .pos-img {
    width: 350px;
  }

  .free-trial {
    padding: 12px 20px;
    font-size: 18px;
  }

  .talk-to-sales {
    padding: 12px 20px;
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .top-container-logo {
    height: 70px;
    width: 250px;
  }
  .top-container-body-content {
    align-items: start;
  }
  .maximize {
    text-align: left;
  }
  .erp {
    text-align: left;
  }

  .pos-img {
    width: 250px;
    margin-top: 10px;
  }
}
@media (max-width: 500px) {
  .maximize {
    font-size: 25px;
  }
  .erp {
    font-size: 30px;
  }

  .pos-img {
    width: 250px;
    margin-top: 10px;
  }

  .button-container {
    flex-direction: column;
  }

  .free-trial {
    margin-bottom: 20px;
    margin-right: 0;
  }

  .talk-to-sales {
    padding: 12px 20px;
    font-size: 18px;
  }
}
