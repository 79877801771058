.mainDiv {
  background-color: #0d0c17;
  padding: 50px 150px;
  display: flex;
  justify-content: center;
}
.innerDiv {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  max-width: 1000px;
}
.left {
  /* background-color: aqua; */
}
.logoDiv {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.logo {
  height: 50px;
  margin-right: 10px;
}
.name {
  font-size: 25px;
  font-weight: 900;
  color: white;
}
.description {
  font-size: 12px;
  color: white;
  margin-bottom: 20px;
}
.socialLinks {
  display: flex;
}
.social {
  margin-right: 10px;
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px white solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
}
.mid {
  /* background-color: rgb(60, 0, 255); */
  display: flex;
  justify-content: space-evenly;
}
.subTitle {
  font-size: 13px;
  margin-bottom: 10px;
  color: white;
}
.right {
  /* background-color: rgb(255, 0, 247); */
  display: flex;
  justify-content: end;
}
.title {
  color: white; /* Adjust this color to match your design */
  margin-bottom: 20px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 600;
}

.subscribeForm {
  position: relative;
  /* width: 300px; */
}
.input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px 100px 10px 20px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
}
.button {
  position: absolute;
  right: 6px;
  top: 3px;
  bottom: 3px;
  border: 0;
  background: #3cba68;
  color: #fff;
  outline: none;
  margin: 0;
  display: flex;
  align-self: center;
  justify-content: center;
  border-radius: 5px;
  z-index: 2;
  padding: 6px 10px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}
.arrow {
  font-size: 10px;
  margin-left: 5px;
}
.a {
  text-decoration: none;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .mainDiv {
    padding: 50px 60px;
  }
}

@media (max-width: 900px) {
  .innerDiv {
    grid-template-columns: 1fr 1fr;
  }
  .right {
    /* background-color: rgb(255, 0, 247); */
    display: flex;
    justify-content: start;
  }
}

@media (max-width: 576px) {
  .innerDiv {
    grid-template-columns: 1fr;
  }
  .right {
    /* background-color: rgb(255, 0, 247); */
    display: flex;
    justify-content: start;
  }
  .mid {
    /* background-color: rgb(60, 0, 255); */
    display: flex;
    justify-content: start;
  }
  .quickLinks {
    margin-right: 50px;
  }
}
