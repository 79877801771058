.infoSectionContainer {
  background-color: #f8f8f8;
  padding: 30px 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleDiv {
  font-family: Outfit;
  font-size: 50px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 30px;
  max-width: 1200px;
}
.premiumText {
  color: #3cba68;
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 60px;
}
.infoCardsDiv {
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  align-items: center;
  align-items: stretch;
}
.infoCard {
  background-color: white;
  padding: 15px 20px 20px 20px;
  border-radius: 10px;
  /* min-height: 355px; */
  /* height: 100%; */
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cardIcon {
  font-size: 50px;
  color: #e18566;
  height: 50px;
  /* background-color: #3cba68; */
  display: flex;
  align-items: end;
}
.cardTitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  min-height: 20px;
  /* background-color: aqua; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3; /* For non-webkit browsers */
  box-orient: vertical; /* For non-webkit browsers */
  text-overflow: ellipsis;
}
.cardDescription {
  font-size: 12px;
  opacity: 0.5;
  font-weight: 400;
  margin-bottom: 10px;
}
.cardLink {
  color: #3cba68;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: end;
}
.cardLinkIcon {
  padding-left: 5px;
}
.link {
  text-decoration: none;
  cursor: pointer;
}
@media (max-width: 1500px) {
  .infoSectionContainer {
    padding: 30px 150px;
  }
}
@media (max-width: 1200px) {
  .infoSectionContainer {
    padding: 30px 80px;
  }
}
@media (max-width: 1000px) {
  .infoSectionContainer {
    padding: 30px 50px;
  }
}
@media (max-width: 900px) {
  .infoCardsDiv {
    grid-template-columns: 1fr 1fr;
  }
  .titleDiv {
    font-size: 40px;
  }
  .premiumText {
    font-size: 50px;
  }
}
@media (max-width: 576px) {
  .cardTitle {
    font-size: 14px;
  }
  .infoCardsDiv {
    grid-template-columns: 1fr;
  }
  .titleDiv {
    font-size: 20px;
  }
  .premiumText {
    font-size: 24px;
  }
}
