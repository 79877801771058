.mainDiv {
  background-color: #f8f8f8;
  padding: 30px 60px;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
}
.posFeaturesDiv {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #3cba68;
}
.everyFeaturesDiv {
  font-family: Outfit;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  color: #000000;
  padding-bottom: 20px;
}
.cardDiv {
  padding: 0 20px 20px 20px;
  margin-bottom: 10px;
}
.card {
  cursor: pointer;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon {
  font-size: 50px;
  margin-bottom: 10px;
  color: #52c8c4;
}
.name {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
@media (max-width: 900px) {
  .posFeaturesDiv {
    font-size: 18px;
  }
  .everyFeaturesDiv {
    font-size: 18px;
  }
  .card {
    height: 150px;
  }
  .icon {
    font-size: 40px;
    margin-bottom: 5px;
  }
  .name {
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .posFeaturesDiv {
    font-size: 14px;
  }
  .everyFeaturesDiv {
    font-size: 14px;
  }
  .card {
    height: 100px;
  }
  .icon {
    font-size: 30px;
  }
  .name {
    font-size: 12px;
  }
}
