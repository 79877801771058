.mainDiv {
  background-color: #f8f8f8;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.banner {
  background: radial-gradient(circle at center 30%, #3d0d45, #0a204b);
  position: relative;
  min-height: 400px;
  width: 100%;
  border-radius: 20px;
  z-index: 1;
}
.img {
  position: absolute;
  top: 20px;
  right: 0;
  height: 400px;
  z-index: 2;
}
.info {
  padding: 50px 0 50px 50px;
  /* background-color: aqua; */
  /* min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; */
}
.title {
  color: white;
  font-size: 50px;
  font-weight: 600;
  text-align: left;
  word-wrap: break-word;
  width: 60%;
  margin-bottom: 20px;
}
.mobImgDiv {
  display: none;
}
.description {
  color: #bebebe;
  width: 40%;
  font-size: 20px;
  font-weight: 300;
}
.btnDiv {
  display: flex;
  margin-top: 60px;
}
.trialBtn {
  border-radius: 10px;
  background-color: white;
  color: black;
  padding: 15px 20px;
  margin-right: 20px;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  cursor: pointer;
}
.purchaseBtn {
  border-radius: 10px;
  background-color: #3cba68;
  color: white;
  padding: 15px 20px;
  margin-right: 20px;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  cursor: pointer;
}
.links {
  text-decoration: none;
}
@media (max-width: 1450px) {
  .img {
    top: 100px;

    height: 300px;
  }

  @media (max-width: 1200px) {
    .img {
      top: 100px;

      height: 270px;
    }

    .title {
      font-size: 40px;
    }
    .description {
      font-size: 18px;
    }

    .trialBtn {
      font-size: 18px;
    }
    .purchaseBtn {
      font-size: 18px;
    }
  }

  @media (max-width: 1000px) {
    .img {
      display: none;
    }

    .info {
      padding: 50px 50px 50px 50px;
    }
    .mobImgDiv {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .mobImg {
      height: 200px;
    }
    .title {
      width: 100%;
      text-align: center;
    }
    .description {
      width: 100%;
      text-align: center;
    }
    .btnDiv {
      display: flex;
      margin-top: 60px;
      justify-content: center;
    }

    .trialBtn {
      font-size: 18px;
    }
    .purchaseBtn {
      font-size: 18px;
    }
  }

  @media (max-width: 900px) {
    .title {
      font-size: 30px;
    }
    .description {
      font-size: 16px;
    }
    .btnDiv {
      margin-top: 30px;
    }

    .trialBtn {
      font-size: 16px;
    }
    .purchaseBtn {
      font-size: 16px;
    }
  }

  @media (max-width: 650px) {
    .title {
      font-size: 20px;
    }
    .mobImg {
      height: 150px;
    }
    .description {
      font-size: 14px;
    }

    .trialBtn {
      font-size: 14px;
    }
    .purchaseBtn {
      font-size: 14px;
    }
    .trialBtn {
      font-size: 12px;
    }
    .purchaseBtn {
      font-size: 12px;
    }
  }

  @media (max-width: 576px) {
    .info {
      padding: 50px 30px 50px 30px;
    }
    .title {
      font-size: 18px;
    }
    .mobImg {
      height: 100px;
    }
    .description {
      font-size: 14px;
    }
    .btnDiv {
      flex-direction: column;
    }
    .trialBtn {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 400px) {
    .info {
      padding: 30px 20px 30px 20px;
    }
  }
}
