html {
  scroll-behavior: smooth;
}

.container {
  padding: 0;
  margin: 0;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--red);
  z-index: 20;
  transform-origin: 0%;
}
